@import "tw-elements-react/dist/css/tw-elements-react.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Стилизация скроллбара для WebKit-браузеров */
::-webkit-scrollbar {
  width: 12px;
  /* ширина для вертикального скроллбара */
  height: 12px;
  /* высота для горизонтального скроллбара */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* цвет дорожки */
}

::-webkit-scrollbar-thumb {
  background: rgb(85 85 85 / .5);
  /* цвет самого скроллбара */
  border-radius: 6px;
  /* скругление углов */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(85 85 85 / .2);
  /* цвет при наведении */
}

/* Стилизация скроллбара для Firefox */
* {
  scrollbar-width: thin;
  /* "auto" или "thin" */
  scrollbar-color: #888 #f1f1f1;
  /* цвет скроллбара и дорожки */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* В вашем CSS файле */
.custom-checkbox {
  /* ... Ваши стили ... */
}

.custom-checkbox:checked {
  /* ... Ваши стили для состояния :checked ... */
}

code:not([class*=language-]) {
  font-weight: 400;
  color: #b93993;
  line-height: inherit;
  font-size: inherit;
  background-color: #f1f3f8;
  padding: 0.1rem 0.4rem;
  margin: 0 0.5rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .08);
  border-radius: 0.3rem;
}